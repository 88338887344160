import type { HTMLAttributes } from 'react';
import React, { useRef } from 'react';
import classNames from 'classnames';
import { useModalQueue } from '$util/hooks/useModalQueue';

import styles from './index.module.scss';

export interface VideoModalProps extends HTMLAttributes<HTMLElement> {
  readonly videoURL: string;
  readonly mute: boolean;
  readonly openCallback?: () => void;
}

/**
 * @deprecated to be dropped or replaced in upcoming design system; avoid used
 */
export function VideoModal({ videoURL, className, mute, openCallback }: VideoModalProps): JSX.Element | null {
  const videoFrameRef = useRef<HTMLIFrameElement>(null);

  const [, queueModal] = useModalQueue();

  const openModal = () => {
    queueModal([
      // eslint-disable-next-line react/jsx-key
      <div className={styles.iframeContainer}>
        <iframe
          ref={videoFrameRef}
          title="Fixter Video"
          className={styles.videoIFrame}
          src={`${videoURL}?autoplay=1${mute ? '&muted=1' : ''}`}
          width="100%"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>,
      'video-modal',
    ]);

    if (openCallback) {
      openCallback();
    }
  };

  return (
    <button type="button" className={classNames(styles.component, className)} onClick={openModal}>
      Play video
    </button>
  );
}

VideoModal.displayName = 'VideoModal';
