'use client';

import type { AnchorHTMLAttributes, ReactText, MouseEvent } from 'react';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Icon } from '@/components/atoms/Icon';

import styles from './index.module.scss';

export type LinkType = 'small' | 'fat' | 'big' | 'L' | 'M' | 'S' | 'XS';
type LinkDirection = 'left' | 'right' | 'none';
type LinkMode = 'dark' | 'light';
export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  readonly children: ReactText;
  readonly type: LinkType;
  readonly mode?: LinkMode;
  readonly direction?: LinkDirection;
  readonly colorBg?: boolean;
  readonly disabled?: boolean;
  readonly purpleIcon?: boolean;
  readonly iconId?: string;
  readonly onClick?: (e: React.MouseEvent) => void;
}

/** @TODO update arrow SVG and replace */
/** @TODO animate/transition link underline on hover */
/** @TODO don't show focus state when clicked with pointer device? check with Aloun */

export function Link({
  href = '',
  target = '_self',
  onClick,
  className,
  type,
  mode = 'dark',
  direction = 'right',
  colorBg,
  disabled,
  iconId,
  purpleIcon,
  children,
  ...props
}: LinkProps): JSX.Element {
  const clickHandler = useCallback(
    (event: MouseEvent) => (disabled || !href) && event.preventDefault(),
    [disabled, href]
  );
  const icon = iconId || 'arrows/arrow-right';
  const consolidatedClasses = [
    Link.displayName,
    styles.component,
    styles[type],
    disabled && styles.disabled,
    type === 'fat' && colorBg && styles['color-bg'],
    direction === 'left' && styles.left,
    mode === 'light' && styles.light,
    className,
  ];

  return (
    <a
      href={href}
      target={target}
      className={classNames(...consolidatedClasses)}
      onClick={onClick ?? clickHandler}
      {...props}
    >
      {children}
      {direction !== 'none' && (
        <Icon
          className={classNames('Link__icon', styles.qvIcon, purpleIcon && styles['purple-icon'])}
          id={icon}
          legacy={false}
        />
      )}{' '}
    </a>
  );
}

Link.displayName = 'Link';
