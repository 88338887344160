import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Icon } from '@/components/atoms/Icon';
import styles from './index.module.scss';

interface CollapseProps {
  title: React.ReactNode | string;
  children: React.ReactNode | string;
  expanded?: boolean;
  elevated?: boolean;
  onExpand?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

function Collapse({
  title,
  children,
  expanded = false,
  elevated = false,
  onExpand,
  className,
  style,
}: CollapseProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(expanded);

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  const toggle = () => {
    setIsOpen(!isOpen);
    if (onExpand) {
      onExpand();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggle();
    }
  };

  return (
    <div
      className={classNames(
        styles.collapseContainer,
        className,
        { [styles.isOpen]: isOpen },
        { [styles.isElevated]: elevated }
      )}
      style={style}
    >
      <div
        className={styles.collapseHeader}
        onClick={toggle}
        role="button"
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {title}
        <Icon
          id="arrows/chevron-down"
          legacy={false}
          className={classNames(styles.icon, { [styles.rotate]: isOpen })}
        />
      </div>
      <div className={classNames(styles.collapseContent, { [styles.hidden]: !isOpen })}>{children}</div>
    </div>
  );
}

Collapse.displayName = 'Collapse';

export default Collapse;
