import type { misc } from './vars';
import vars from './vars';

export type MediaQueryBreakpoint = keyof typeof DEFAULT_BREAKPOINTS;

/**
 * @deprecated use `src/styles/variables/_breakpoints.scss`
 */
export const DEFAULT_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const getBreakpoint = (
  screenSize: keyof (typeof misc)['mediaQueryBreakpoints'],
  extraPixel = 0
): string => `${vars.mediaQueryBreakpoints[screenSize] + extraPixel}px`;
