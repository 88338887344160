'use client';

import type { HTMLAttributes } from 'react';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import type { CmsImage } from '$cms/types';
import { isBrowser } from '$util/index';
import { VideoModal } from '$components/composite/VideoModal';
import { Tag } from '$quantum/atoms/Tag';
import { VideoPlayButton } from '$quantum/atoms/VideoPlayButton';

import { Img } from '@/components/atoms/Img';
import styles from './index.module.scss';

export interface MediaBlockProps extends HTMLAttributes<HTMLElement> {
  readonly image: Omit<CmsImage, 'key'>;
  readonly video?: {
    readonly url: string;
    readonly title?: string;
    readonly tag?: string;
  };
}

/**
 * Displays an Image or an Image with a video play button that triggers an embedded video.
 */
export function MediaBlock({ image, video, className, ...props }: MediaBlockProps): JSX.Element {
  const width = useMemo(() => (isBrowser ? window.innerWidth : -1), []);

  return (
    <article
      className={classNames(MediaBlock.displayName, styles.component, video && styles.video, className)}
      {...props}
    >
      <Img
        provider="cloudinary"
        src={image.url}
        alt={image.alt}
        title={image.title}
        width={image.width}
        height={image.height}
      />
      {video && (
        <div className={styles.videoInfo}>
          <div className={styles.videoPlayButton}>
            <VideoPlayButton disabled disabledButLooksOn />
          </div>
          {video.title && (
            <div className={styles.description} aria-label="description">
              <h1 className={styles.title}>{video.title}</h1>
              {video.tag && <Tag>{video.tag}</Tag>}
            </div>
          )}
          {video.url && <VideoModal videoURL={video.url} mute={width > 0 && width < 897} />}
        </div>
      )}
    </article>
  );
}
MediaBlock.displayName = 'MediaBlock';
