import { useEffect, useRef } from 'react';

/**
 * Useful when needing the previous value of a prop.
 */
export const usePreviousValue = <T>(current: T): T => {
  const prevRef = useRef(current);
  useEffect(() => {
    prevRef.current = current;
  }, [current]);
  return prevRef.current;
};
