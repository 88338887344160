export const nowInDateRange = (startDate?: string, endDate?: string): boolean => {
  const now = new Date().getTime();

  if (startDate) {
    const startTimestamp = new Date(startDate).getTime();
    if (Number.isNaN(startTimestamp) || startTimestamp > now) return false;
  }

  if (endDate) {
    const endTimestamp = new Date(endDate).getTime();
    if (Number.isNaN(endTimestamp) || endTimestamp < now) return false;
  }

  return true;
};
