/**
 * @note we want this to be a Client Component because the CDN cache
 * might return a banner with a campaign that has expired in the meantime
 * so we want to evaluate if to show the banner on the client also.
 */

'use client';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import classNames from 'classnames';
import { Segment } from '@fixter/i18n';
import { useActiveExperiment, useKameleoonExperimentsLoaded } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';
import { Icon } from '@/components/atoms/Icon';
import { nowInDateRange } from './nowInDateRange';

import styles from './index.module.scss';

export type TopNavBannerType = 'primary' | 'secondary';

export interface TopNavBannerClientProps extends ComponentPropsWithoutRef<'div'> {
  readonly type: TopNavBannerType;
  readonly icon?: string;
  readonly startDate?: string;
  readonly endDate?: string;
  readonly segment?: Segment;
  readonly source?: string;
}

/**
 * @warn use this for Page Router and TopNavBanner for App Router
 * @todo consolidate back in TopNavBanner when it's not using server only code anymore
 */
export function TopNavBannerClient({
  type,
  icon,
  startDate,
  endDate,
  segment,
  source,
  children,
  className,
  ...props
}: TopNavBannerClientProps): ReactNode {
  const isABTestLongTailPagePromoBannerUK = useActiveExperiment(
    ActiveExperiments.LongTailPagePromoBannerUK,
    'B'
  );
  const loaded = useKameleoonExperimentsLoaded();
  /**
   * In order to stop the banner from flickering we hide it while Kameleoon Experiments are not active yet
   * and then show them later if the experiment does not exist of if Reference variant
   * Do not hide secondary banners because they are used with MOT banners
   * Do not hide the banner if it's a PPC campaign
   */
  const hideBanner =
    (isABTestLongTailPagePromoBannerUK || !loaded) &&
    type === 'primary' &&
    source !== 'cookie' &&
    source !== 'url';
  if (segment === Segment.Fixter_UK && hideBanner) return null;
  if (!nowInDateRange(startDate, endDate)) return null;
  return (
    <div
      className={classNames(TopNavBannerClient.displayName, styles.component, styles[type], className)}
      {...props}
    >
      {icon && <Icon id={icon} legacy={false} />}
      <div>{children}</div>
    </div>
  );
}
TopNavBannerClient.displayName = 'TopNavBanner';
