import { useEffect } from 'react';
import type { misc } from '$util/theme/vars';
import { getBreakpoint } from '$util/theme/breakpoints';

type BreakpointKeys = keyof (typeof misc)['mediaQueryBreakpoints'];
interface UseResizeViewportProps {
  breakpoint: BreakpointKeys | [BreakpointKeys, BreakpointKeys];
  direction?: 'up' | 'down' | 'between';
  fn: (matches: boolean) => void;
}

export function useResizeViewport({ breakpoint, fn, direction = 'up' }: UseResizeViewportProps): void {
  useEffect(() => {
    let query = '';

    if (Array.isArray(breakpoint)) {
      const [from, till] = breakpoint;

      const fromWidth = parseInt(getBreakpoint(from), 10);
      const tillWidth = parseInt(getBreakpoint(till), 10);

      query = `(${fromWidth}px <= width < ${tillWidth}px)`;
    } else {
      const width = parseInt(getBreakpoint(breakpoint), 10);
      const up = direction === 'up';

      query = `(${up ? 'min' : 'max'}-width: ${up ? width : +width - 1}px)`;
    }

    const mql = window.matchMedia(query);

    const fnWrapper = (e: MediaQueryListEvent) => fn(e.matches);

    mql.addEventListener('change', fnWrapper);

    fn(mql.matches);

    return () => mql.removeEventListener('change', fnWrapper);
  }, [fn, breakpoint, direction]);
}
