//! server-and-client

import type { CookieAttributes } from 'js-cookie';

/**
 * !!!IMPORTANT!!!
 * If any cookie is needed to be read server-side it has to be whitelisted
 * for forward in terraform `cf.ts` for `default_cache_behavior`.
 */
const COOKIE_OPTIONS = Object.freeze({
  fixterSessionId: {
    httpOnly: false,
    // expires: session
  },
  fixterAnalyticsSessionId: {
    httpOnly: false,
    // expires: session
  },
  kameleoonVisitorCode: {
    httpOnly: false,
    // expires: session
  },
  fixterAccessToken: {
    httpOnly: false,
    expires: 30, // days
  },
  fixterAdminLogin: {
    httpOnly: false,
    expires: 0.5, // days
  },
  customer: {
    httpOnly: false,
    expires: 30, // days
  },
  fixterLanding: {
    httpOnly: false,
    expires: 7, // days
  },
  fixterHome: {
    httpOnly: false,
    expires: 7, // days
  },
  trackBooking: {
    httpOnly: false,
    expires: 7, // days
  },
  fixterPartner: {
    httpOnly: false,
    expires: 1, // days
  },
  acquisitionMethod: {
    httpOnly: false,
    expires: 1, // days
  },
  fixterVrm: {
    httpOnly: false,
    // expires: session
  },
  fixterPostcode: {
    httpOnly: false,
    // expires: session
  },
  EnableMotServiceBundle: {
    httpOnly: false,
    // expires: session
  },
  fixterEmailCampaign: {
    httpOnly: false,
    expires: 7, // days
  },
  fixterMarketingCampaign: {
    httpOnly: false,
    expires: 7, // days
  },
  fixterMembershipGarage: {
    httpOnly: false,
  },
  fixterSegment: {
    httpOnly: false,
    expires: 7, // days
  },
  collectionType: {
    httpOnly: false,
    // expires: session
  },
  monMecRedirect: {
    httpOnly: false,
    // expires: session
  },
  activeCampaignData: {
    httpOnly: false,
  },
  awc: {
    httpOnly: false,
    expires: 30,
  },
  shouldTriggerCustomerCnDMapView: {
    httpOnly: false,
  },
  shouldTriggerLiquidCrossSellFlow: {
    httpOnly: false,
  },
}) satisfies CookieAttributes;

export const getCookieOptions = (
  cookieName: keyof typeof COOKIE_OPTIONS,
  domain: string,
  secure?: boolean
): CookieAttributes => ({
  domain,
  secure,
  ...COOKIE_OPTIONS[cookieName],
});
