import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import { HeadingLevels } from '../../../src/components/atoms/HeadingLevels';

export interface HeadingProps {
  readonly children: ReactNode;
  readonly className?: string;
}

export function Heading({ children, className }: HeadingProps): JSX.Element {
  return (
    <HeadingLevels className={classNames(Heading.displayName, className)} styleLevel={2} semanticLevel={2}>
      {children}
    </HeadingLevels>
  );
}

Heading.displayName = 'Heading';
