'use client';

import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { getCookieOptions } from '@/util/getCookieOptions';
import { getClientTld } from '@/util/domain';
import { setFixterLanding, maybeSetMotServiceBundle } from './cookies';

interface PageSetupEffectsClientProps {
  readonly secureCookies?: boolean;
  readonly campaignId?: string;
  readonly setCampaignCookie?: boolean;
  readonly awc?: string;
}

export function PageSetupEffectsClient({
  secureCookies = false,
  campaignId,
  setCampaignCookie,
  awc,
}: PageSetupEffectsClientProps): null {
  useEffect(() => {
    if (campaignId && setCampaignCookie) {
      const options = getCookieOptions('fixterMarketingCampaign', window.location.hostname, secureCookies);
      Cookies.set('fixterMarketingCampaign', campaignId, options);
    }
  }, [campaignId, setCampaignCookie, secureCookies]);

  useEffect(() => {
    if (awc) {
      const options = getCookieOptions('awc', window.location.hostname, secureCookies);
      Cookies.set('awc', awc, options);
    }

    setFixterLanding(secureCookies);
    maybeSetMotServiceBundle(secureCookies);

    Cookies.remove(
      'fixterMembershipGarage',
      getCookieOptions('fixterMembershipGarage', getClientTld(), secureCookies)
    );
    Cookies.remove(
      'fixterMembershipGarageDomain',
      getCookieOptions('fixterMembershipGarage', getClientTld(), secureCookies)
    );
  }, [awc, secureCookies]);

  return null;
}
