'use client';

import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import classNames from 'classnames';
import type { AnyObject } from '$util/types';

import { useModalQueue } from '$util/hooks/useModalQueue';
import { Button, DesignType } from '@/components/atoms/Button';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import { isEnvStage } from '@/util';
import styles from './index.module.scss';

interface CmsKeysData {
  readonly propName: string;
  readonly key: string;
  readonly value?: string;
}

export interface CmsKeyInspectorHUDProps extends ComponentPropsWithoutRef<'aside'> {
  readonly translations: AnyObject;
  readonly componentUiName?: string;
}

export function formatCmsTranslationsToKeysData(translations: AnyObject): CmsKeysData[] {
  return Object.entries(translations).map(([propName, { key, ...rest }]) => ({
    propName,
    key,
    value: rest?.value || undefined,
  }));
}

export function CmsKeyInspectorHUD({
  translations,
  componentUiName,
  ...props
}: CmsKeyInspectorHUDProps): JSX.Element | false {
  const [, queueModal] = useModalQueue();

  const openInspector = () =>
    queueModal(
      <div className={styles.HUDElement}>
        {componentUiName && (
          <HeadingLevels styleLevel={3} semanticLevel={1}>
            {componentUiName}
          </HeadingLevels>
        )}
        {formatCmsTranslationsToKeysData(translations).map(({ propName, key, value }) => (
          <div key={key} className={styles.cmsKey}>
            <span className={styles.propName}>{propName}:</span>{' '}
            <a
              href={`https://app.contentful.com/spaces/xfajj2xljhpt/views/entries?searchText=${key}&contentTypeId=&contentTypeIds=&displayedFieldIds=contentType&displayedFieldIds=updatedAt&displayedFieldIds=author&order.direction=descending&order.fieldId=updatedAt&filters=`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {key}
            </a>
            {value && (
              <details className={styles.valueDisclosure}>
                <summary>Content</summary>
                <p className={styles.valueDisclosureContent}>{value}</p>
              </details>
            )}
          </div>
        ))}
      </div>
    );

  return (
    ((isEnvStage('development') || isEnvStage('staging')) && (
      <div className={classNames(styles.component, 'HUD')} {...props}>
        <Button
          className={styles.openButton}
          size="XS"
          designType={DesignType.CTA_DARK}
          iconId="general/info-circle"
          iconOnly
          onClick={openInspector}
        />
      </div>
    )) ||
    false
  );
}
CmsKeyInspectorHUD.displayName = 'CmsKeyInspectorHUD';
