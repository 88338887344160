'use client';

import type { HTMLAttributes } from 'react';
import React, { useMemo } from 'react';
import classNames from 'classnames';
import type { Settings, CustomArrowProps } from 'react-slick';
import Slider from 'react-slick';
import { Icon } from '@/components/atoms/Icon/index';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './index.module.scss';

const DESKTOP_PADDING = '56px';
const TABLET_PADDING = '50px';
const MOBILE_PADDING = '44px';

export interface CarouselBaseProps extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element[];
  additionalSettings?: Settings;
}

function NextArrow({ onClick }: CustomArrowProps) {
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button className="slick-arrow next" type="button" onClick={onClick}>
      <Icon id="icon_arrow-right" width={12} />
    </button>
  );
}

function PrevArrow({ onClick }: CustomArrowProps) {
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button className="slick-arrow prev" type="button" onClick={onClick}>
      <Icon id="icon_arrow-right" className="slick-arrow-prev" width={12} />
    </button>
  );
}

export function CarouselBase({
  className,
  children,
  additionalSettings,
  ...props
}: CarouselBaseProps): JSX.Element {
  const settings: Settings = useMemo(() => {
    return {
      centerMode: true,
      infinite: true,
      centerPadding: DESKTOP_PADDING,
      slidesToShow: children.length > 5 ? 4 : children.length,
      speed: 500,
      arrows: true,
      useTransform: false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1441,
          settings: {
            slidesToShow: 3,
            centerPadding: TABLET_PADDING,
          },
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 2,
            centerPadding: TABLET_PADDING,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            centerPadding: TABLET_PADDING,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerPadding: MOBILE_PADDING,
          },
        },
      ],
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      ...additionalSettings,
    };
  }, [children.length, additionalSettings]);

  return (
    <div className={classNames(CarouselBase.displayName, styles.component, className)} {...props}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
CarouselBase.displayName = 'CarouselBase';
