import type { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Icon } from '@/components/atoms/Icon';
import styles from './index.module.scss';

export interface VideoPlayButtonProps extends HTMLAttributes<HTMLButtonElement> {
  readonly disabled?: boolean;
  readonly disabledButLooksOn?: boolean;
}

export function VideoPlayButton({
  disabled,
  onClick,
  disabledButLooksOn,
  className,
  ...props
}: VideoPlayButtonProps): JSX.Element {
  return (
    <button
      type="button"
      className={classNames(
        styles.videoPlayButton,
        VideoPlayButton.displayName,
        disabled && disabledButLooksOn ? styles.looksOn : '',
        className
      )}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <Icon id="icon_play" className={styles.playIcon} width={18} />
    </button>
  );
}

VideoPlayButton.displayName = 'VideoPlayButton';
