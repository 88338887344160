import type { HTMLAttributes, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import type { Writable } from 'type-fest';
import type { AnyObject } from '$util/types';
import styles from './index.module.scss';

export type TagType = 'small' | 'medium';

export interface TagProps extends HTMLAttributes<HTMLElement> {
  readonly type?: TagType;
  readonly bgColor?: string;
  readonly textColor?: string;
  readonly noBorder?: boolean;
  readonly children: ReactNode;
}

export function Tag({
  children,
  type = 'small',
  className,
  bgColor,
  textColor,
  noBorder,
  ...props
}: TagProps): JSX.Element {
  const extraStyles: Writable<AnyObject> | undefined =
    ((bgColor || textColor || noBorder) && {}) || undefined;

  if (extraStyles && bgColor) extraStyles.backgroundColor = bgColor;
  if (extraStyles && textColor) extraStyles.color = textColor;
  if (extraStyles && noBorder) extraStyles.border = 'none';

  return (
    <em className={classNames(Tag.displayName, type, className, styles.Tag)} style={extraStyles} {...props}>
      {children}
    </em>
  );
}
Tag.displayName = 'Tag';
